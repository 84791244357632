@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600&display=swap');

.container-form {
    max-width: 421px;
}

body {
    max-height: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
}
.main-logo-container {
    width: 253px;
    height: 129px;
    margin: auto;
}

.main-logo {
    position: relative;
    bottom: 40%;
}

.main-logo .rect-1 {
    position: absolute;
    left: 35%;
    width: 100px;
    height: 100px;
    bottom: 0;
}

.main-logo .rect-2 {
    position: absolute;
    width: 100px;
    height: 100px;
    left: 43%;
    bottom: 0;
}

.main-logo-head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 40.1172px;
    line-height: 48px;
    color: #76767A;
    text-align: center;
}


.email-form input, .password-form input, .name-form input{
    width: 405px;
    height: 40px;
    border: 1px solid #A7A8AB;
    text-indent: 15px;
    border-radius: 8px;
    margin-top: 8px;
    color: #76767A;
}

form {
    margin-top: -50px;
}

.email-form span, .password-form span, .name-form span{
    font-size: 14px;
    line-height: 18px;
    font-family: 'Inter';

}

.password-form{
    margin-top: 16px;
    position: relative;
}

.name-form {
    margin-bottom: 18px;
}
.password-form img {
    position: absolute;
    right: 7%;
    bottom: 17%;
}
.button-log-in button{
    margin-top: 32px;
    background-color: #55B37A;
}

.button-vk-id button {
    margin-top: 16px;
    background-color: #2787F5;
}

.button-log-in button, .button-vk-id button {
    width: 405px;
    height: 48px;
    color: white;
    border-radius: 8px;
    font-size: 14px;
    font-family: 'Inter';
    font-weight: 500;
}