@tailwind base
@tailwind components
@tailwind utilities


.blue-button
  background: linear-gradient(315deg, #503DF6 0%, #5CC6ED 84.37%)

body
  background: #F5F5F7

html
  font-family: 'Ubuntu', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"

.pretty-scroll
    &::-webkit-scrollbar
      height: 8px
      width: 8px
      @apply rounded-full
    &::-webkit-scrollbar-thumb
      @apply bg-btn-secondary
      @apply rounded-full
.pretty-scroll2
  &::-webkit-scrollbar
    height: 5px
    width: 5px
    @apply rounded-full
  &::-webkit-scrollbar-thumb
    @apply bg-btn-secondary
    @apply rounded-full
.no-scroll-bar
  -ms-overflow-style: none
  scrollbar-width: none

  &::-webkit-scrollbar
    display: none

textarea::-webkit-resizer
  background: no-repeat url('../images/textarea.svg')
  background-size: 80%

.ql-container.ql-snow, .ql-toolbar.ql-snow
  border: 1px solid #A7A8AB !important

.ql-toolbar.ql-snow
  border-radius: 8px 8px 0 0 !important

.ql-container.ql-snow
  width: 100% !important
  border-radius: 0 0 8px 8px !important

.quill.error-border
  .ql-container.ql-snow, .ql-toolbar.ql-snow
    border: 1px solid #FF6666 !important


.ql-editor
  font-family: 'Ubuntu', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
  font-size: 14px

.modal-wrapper
  grid-template-rows: minmax(min-content, auto) minmax(min-content, auto) minmax(min-content, auto)
  grid-template-columns: auto minmax(0, 1fr) auto
  background-color: rgba(0,0,0,.4)
  top: 0
  left: 0
  z-index: 2000

.modal
  grid-template-columns: minmax(0, 100%)
  grid-row: 2
  grid-column: 2
  align-self: center
  justify-self: center

.company-menu
  scrollbar-width: thin
  scrollbar-track-color: white
  scrollbar-gutter: stable
  scrollbar-color: rgb(214,237,223) white
  scrollbar-arrow-color: white