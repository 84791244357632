@import "react-color-palette/lib/css/styles.css"

.rcp-light
  --rcp-background: #ffffff
  --rcp-input-text: #111111
  --rcp-input-border: rgba(145, 49, 49, 0.1)
  --rcp-input-label: #717171
  border: 2px solid rgba(145, 49, 49, 0.1)
  //width: 100% !important
  //grid-template-columns: minmax(0, 100%)
  //grid-row: 2
  //grid-column: 2
  //align-self: center
  //justify-self: center
