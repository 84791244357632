@import "reactflow/dist/style.css"

.react-flow__attribution
  display: none

.react-flow__handle
  width: 11px
  height: 11px
  border-radius: 100%
  background: rgba(118, 118, 122, 0.4)

.react-flow__edge-path
  stroke-width: 2
  z-index: 100


